<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import { VxTable, VxCellDate, VxCellPlayerAvatar, VxCellRefillBonus, VxCellPaymentStatus } from '@/components/table'
import { passDataToResource, payments, paymentStatuses, paymentSystems, paymentSystemsMap } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { moneyFormat } from '@/services/utils'

export default {
  name: 'Payments',
  components: {
    VxTable,
    VxCellDate,
    VxCellPlayerAvatar,
    VxCellRefillBonus,
    VxCellPaymentStatus
  },
  setup () {
    const resource = passDataToResource(payments.getAll, {
      requestParams: {
        params: { include: 'account,paymentRefillBonus.refillBonus,account.player,metadata' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'account.player',
        label: 'Player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'main_amount',
        label: 'Amount',
        formatter: (mainAmount, key, { gate_amount, gateway }) => {
          if (gate_amount && gateway === paymentSystemsMap.giftCard) {
            return `${moneyFormat(mainAmount)} (${moneyFormat(gate_amount)})`
          }

          return moneyFormat(mainAmount)
        }
      },
      {
        key: 'gateway',
        label: 'Payment System'
      },
      { key: 'external_id' },
      {
        key: 'status',
        component: VxCellPaymentStatus
      },
      {
        key: 'refill_bonus',
        component: VxCellRefillBonus,
        tdAttr: (value, key, { gateway, refill_bonus_status }) => ({
          gateway,
          status: refill_bonus_status
        })
      },
      {
        key: 'created_at',
        component: VxCellDate,
        tdAttr: { showTime: true }
      },
      {
        key: 'updated_at',
        component: VxCellDate,
        tdAttr: { showTime: true }
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      { key: 'external_id' },
      {
        key: 'gateway',
        label: 'Payment System',
        type: filterTypes.serverSelect,
        resource: paymentSystems.getAll,
        reduce: ({ name }) => name,
        optionsLabel: 'name',
        searchable: false
      },
      {
        key: 'status',
        type: filterTypes.select,
        options: Object.values(paymentStatuses)
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['from', 'to']
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
